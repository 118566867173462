import { Component, cloneElement } from 'react'
import axios from 'axios'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'
import { connect } from 'react-redux'
import { getUniqueId } from '@patternfly/react-core'
import { toast } from 'react-toastify'
import { Loader } from './Loader'
import { getLoadStyle } from '../helpers/layout'
import { getCgiUrl } from '../helpers/url'

const SparqlClient = require('sparql-http-client/SimpleClient')


axios.defaults.timeout = 250000; 

//import spcache from '../spcache';

//let cache = spcache;
let cache = {}
if (document.cache != null) {
  cache = document.cache
}

const CancelToken = axios.CancelToken

window.top.saveCache = function () {
  // var s = 'export default ';
  //var s = 'document.cache=';
  var s = ''
  s += JSON.stringify(cache)
  console.log(s)

  window.top.downloadObjectAsJson(s, 'spcache')
}

class Widget extends Component {
  constructor(props) {
    super(props)
    this.resolveTemplateVariableInQueryDebounce = debounce(
      this.resolveTemplateVariableInQuery,
      2000
    )
    this.useLoadingWidget = false
    if (window.top.url == null) {
      this.createSparqlFunction()
    }
  }

  state = {
    loading: false,
  }

  getUrl() {
    try {
      let url = '/api/sparql/' + this.props.repoURL
      if (this.props.repoURL.startsWith('http')) {
        // request sparql endpoint directly
        url = this.props.repoURL
      }
      var modus = false
      if (window.location.host === 'localhost:3000') {
        modus = true
      }
      //  var modus=false; //debug

      if (
        this.props.localRepoURL != null &&
        this.props.localRepoURL !== '' &&
        modus
      ) {
        url = this.props.localRepoURL
      }
      window.top.url = url
    //  console.log('returning url :' + url)
      return url
    } catch (e) {
      console.log('error creating url' + e)
    }
    return null
  }

  createSparqlFunction2() {
    // gebruikt door bb widgets in iframe situation
    var url = this.getUrl()
    window.top.query = async function (query, success, errorRV) {
      // console.log("query via window top ",query,success,errorRV);

      let encodedQuery = encodeURIComponent(query)
      let data = {
        query: query,
      }
      data = 'infer=false&sameAs=false&query=' + encodedQuery

      axios.defaults.headers.post['Content-Type'] =
        'application/x-www-form-urlencoded'
      //axios.defaults.headers.post['Access-Control-Allow-Origin'] ='*';

      //console.log("querying with ",query,headers);

      
    
    if (document.withCredentials==null) {document.withCredentials=true}

      axios.defaults.withCredentials = document.withCredentials;
      if (document.withCredentials !=false)
      {
        axios.defaults.credentials = 'include'
      }
    
      
       if  ( !( (success==null)  && (errorRV==null)) ) 
       {
          var r=axios({  method: 'post',     url,      data,      maxContentLength: Infinity,
          maxBodyLength: Infinity,   headers:{       Accept: 'application/sparql-results+json'      },     cancelToken: new CancelToken((c) => {         this.cancel = c       }),       }); 
          
            r.then((response) => {
              if (success) {
                success.call(this, response.data)
              }
            })
            .catch((error) => {
              //  console.log(error,query);
              if (errorRV) {
                errorRV.call(this, error)
              }
            })
          }
       
      
        else
        {
             console.log("run await version");
        //  var r= await axios({  method: 'post',     url,  data,       headers:{       Accept: 'application/sparql-results+json'      },     cancelToken: new CancelToken((c) => {         this.cancel = c       }),       }).catch(err => false);; 
        var r= await axios.post(url,data,{   maxContentLength: Infinity,
          maxBodyLength: Infinity, headers:{       Accept: 'application/sparql-results+json'      },     cancelToken: new CancelToken((c) => {         this.cancel = c       }),       });; 
         
         
            return  r;
        }
      }
  

    this.sparqlQuery = window.top.query;
  }

  createSparqlFunction() {
    // gebruikt door bb widgets in iframe situation

    if (true) {
      this.createSparqlFunction2()
      return
    }
    var endpointUrl = this.getUrl()
    window.top.query = async function (query, success, errorRV) {
      try {
        const client = new SparqlClient({ endpointUrl,headers:{
          Accept: 'application/sparql-results+json'
        } })
        
        const stream = await client.query.select(query, {
          operation: 'postUrlencoded',
        })
        var data = await stream.json()

        if (success) {
          success.call(this, data)
        }
      } catch (e) {
        console.log(e)
        if (errorRV != null) {
          errorRV.call(this, 'error sparql endpoint')
        }
      }
    }
  }

  runJSInQuery(query) {
    try {
      if (query == null) return null
      let b = query.includes('<script>')
      if (b) {
      //  console.log('FOUND JS in query')

        let codes = query.split('<script>')
        let nquery = ''
        for (let n in codes) {
          if (n === '0') {
            nquery = codes[n]
            continue
          }
          let code = codes[n]

          let codeQuery = code.split('</script>')
          let evalcode = codeQuery[0]
          //  console.log(code,n,evalcode);
          let evalCode2 = new Function(evalcode)() //eslint-disable-line no-new-func
          if (evalCode2 == null) {
            evalCode2 = ''
          }
          let subQuery2 = codeQuery[1]
          nquery += evalCode2 + subQuery2
        }
      //  console.log('returning ', nquery)
        return nquery
      }
    } catch (error) {
      console.error('error parsing js in query ', error, query)
    }

    return query
  }

  async loadData(originalQuery, countRetries = 0) {
    if (countRetries > 1) return console.error('retried this already')
    const me = this

    if (!this.props.repoURL) return console.error('Repo URL is not set!')
    if (!originalQuery) return

    const query = this.runJSInQuery(originalQuery)
    const encodedQuery = encodeURIComponent(query)

    if (this.state.loading) try { this.cancel() } catch (e) {}

    if (countRetries === 0) {
      this.setLoading(true)
      this.setState({ message: null })
    }

    const cachedResults = cache[encodedQuery]
    if (cachedResults) return setTimeout(() => {
      this.setState({ data: cachedResults, id: getUniqueId(), executedSparql: query })
      this.setLoading(false)
    }, 10)

    try {
      const response = await axios({
        method: 'post',
        url: getCgiUrl('sparql', this.props.repoURL, this.props.localRepoURL),
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        data: `infer=false&sameAs=false&query=${encodedQuery}`,
        withCredentials: document.withCredentials ?? true,
        headers:{
          Accept: 'application/sparql-results+json,*/*;q=0.9',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        cancelToken: new CancelToken((c) => me.cancel = c),
      })

      if (!query.includes("#nocache")) cache[encodedQuery] = response.data
      me.setState({ data: response.data })
      this.setLoading(false)

    } catch(error) {
      console.error(error)
      if (error.response?.status === 401 || error.response?.status === 403) {
        const auth = await this.props.reauth()
        if (!auth.success) {
          auth.goToLoginPage()
          return
        }
        return this.loadData(originalQuery)
      }

      console.error(error)
      return this.loadData(originalQuery, countRetries + 1)
    }
  }

  /**
   * @param changedPublishProps {string[]|null}
   */
  resolveTemplateVariableInQuery = (changedPublishProps) => {
    let query = this.props.query
  

    if (this.props.withinSelectedTab === false) {
      return
    } // geen queries uitvoeren voor niet zichtbare tabs
    if (!query) {
      return
    }

    // console.log(query);

    //const regex = /{{\S*}}/g;
    const regex = /{{!?[A-Za-z0-9|:./]*}}/g
    let subscribeProps = query.match(regex)
   
    
    if (!subscribeProps) {
      if (changedPublishProps && changedPublishProps.length) {
        return
      }
      // console.log("load data:"+query.substring(0,30));
      // geen variabelen meer in de query?
      return this.loadData(query)
    }

    //  console.log(subscribeProps);
    subscribeProps = subscribeProps.map((f) => f.replace(/[{}]/g, ''))
    var subscribeProps2 = subscribeProps.map((f) => f.split('||')).flat()

    

    //console.log(subscribeProps2);
    subscribeProps2 = subscribeProps2.map((f) => f.split('::')[0])
    //
    subscribeProps = [...new Set(subscribeProps)] // remove duplicates
    subscribeProps2 = [...new Set(subscribeProps2)] // remove duplicates
    //console.log(subscribeProps2,changedPublishProp);
    if (changedPublishProps && !subscribeProps2.some(subscribeProp => changedPublishProps.includes(subscribeProp))) {
      //this.setState({ data: {results:{bindings:[]},head:{vars:[]}}, loading: false  });
      return
    }

    
    //console.log(query.substring(0,20),subscribeProps,changedPublishProp,!subscribeProps.includes(changedPublishProp));

    for (var i = 0; i < subscribeProps.length; i++) {
      let subscribeProp = subscribeProps[i]
      let subscribeValue = this.props.pubsub[subscribeProp]
      if (subscribeValue == null) {
        // console.log("found empty property or composed properties",subscribeProp);
      }

      if (subscribeProp.includes('||')) {
        let subscribeProp2 = subscribeProp.split('||')[0]
        subscribeValue = this.props.pubsub[subscribeProp2]
        if (subscribeValue === 'http://www.buildingbits.nl/reset') {
          subscribeValue = null
        }

        //  console.log(subscribeProp,subscribeValue,subscribeProp.split(":")[1]);
        if (subscribeValue == null) {
          var prop = subscribeProp.split('||')[1].split('||')[0].split('::')[0]
          subscribeValue = this.props.pubsub[prop]
          if (subscribeValue === 'http://www.buildingbits.nl/reset') {
            subscribeValue = null
          }
        }
      }

      if (subscribeProp.includes('::') && subscribeValue == null) {
        let subscribeProp2 = subscribeProp.split('::')[0]
        subscribeValue = this.props.pubsub[subscribeProp2]
        if (subscribeValue === 'http://www.buildingbits.nl/reset') {
          subscribeValue = null
        }
        // console.log(subscribeProp,subscribeValue,subscribeProp.split(":")[1]);
        if (subscribeValue == null) {
          subscribeValue = subscribeProp.split('::')[1]
        }
        //  console.log(subscribeProp,subscribeValue);
      }

      const negationPattern = /^!(.+)$/
      const negationMatch = subscribeProp.match(negationPattern)
      if (negationMatch) {
        const negatedSubscribeProp = negationMatch[1]
        const value = this.props.pubsub[negatedSubscribeProp]
        const booleanValue = String(value).toLowerCase() === 'true'
        subscribeValue = !booleanValue
      }

      //console.log(subscribeProp,subscribeValue);
      if (subscribeValue == null) {
        return this.setState({
          //message: `Kies een ${subscribeProp}`
          message: '',
        })
      }
      // console.log(subscribeProp,subscribeValue);
      subscribeProp = subscribeProp.replaceAll('||', '\\|\\|')
      let regex2 = new RegExp(`{{${subscribeProp}}}`, 'g')
      // var o={q:query};

      //  console.log("changing query ",query,regex2,subscribeValue);
      try {
        query = query.replace(regex2, subscribeValue)
        // console.log("results ",query);
      } catch (e) {
        console.log(
          'ERROR ',
          query,
          subscribeValue,
          subscribeProps,
          this.props.pubsub[subscribeProp]
        )
      }
      // o.q2=query;
      //console.log(o,subscribeProp);
    }
    this.setLoading(true)
    // console.log("changed query so loading",query.substring(0,20),{query:query});
    this.loadData(query)
  }

  componentDidMount() {
    let variables = this.props.definition.variables
    let queryDefinition =
      variables && variables.find((variable) => variable.name === 'query')
    if (!queryDefinition) return
    if (this.props.withinSelectedTab === false) return
    this.resolveTemplateVariableInQuery()
  }

  componentDidUpdate(prevProps) {
    let variables = this.props.definition.variables
    let queryDefinition =
      variables && variables.find((variable) => variable.name === 'query')
    if (!queryDefinition) return

    if (prevProps.repoURL !== this.props.repoURL) {
      this.resolveTemplateVariableInQueryDebounce()
    } else if (this.props.withinSelectedTab !== prevProps.withinSelectedTab) {
      // if (this.props.withinSelectedTab && !this.state.data){
      if (this.props.withinSelectedTab) {
        this.resolveTemplateVariableInQuery()
      }
    } else if (prevProps.query !== this.props.query) {
      this.resolveTemplateVariableInQuery()
    } else if (!isEqual(prevProps.pubsub, this.props.pubsub)) {
      let changedPublishProps = []

      Object.keys(this.props.pubsub).forEach((key) => {
        if (prevProps.pubsub[key] !== this.props.pubsub[key]) {
          changedPublishProps.push(key)
        }
      })

      this.resolveTemplateVariableInQuery(changedPublishProps)
    }
  }

  publishMultipleValues=(prop_value)=>
  this.props.dispatch({ type: 'PUBLISH', data: prop_value })

  publish = (prop, value) =>
    this.props.dispatch({ type: 'PUBLISH', data: { [prop]: value } })

  /**
   * @param loading {boolean}
   */
  setLoading = loading => {
    this.setState({ loading })

    if (this.props.voteForUserActionsDisabledWhenLoading) {
      this.props.voteForUserActionsDisabled(loading)
    }
  }

  /**
   * @param loading {boolean}
   */
  setLoadingFromWidget = loading => {
    /*
     * This setTimeout was taken from existing code and its purpose is unclear.
     * It may indicate a potential code problem or may not be needed at all.
     * Further investigation is required to determine if it should be removed.
     */
    setTimeout(() => {
      this.setLoading(loading)
    }, 0);
  }

  sendNotification = (msg) => toast(msg)

  render() {
    if (this.state == null) return null

    const { children, ...propsWithoutChildren } = this.props
    return (
      <Loader active={this.state.loading && this.props.showLoadingOverlay}>
        {cloneElement(children, {
          ...this.state,
          ...propsWithoutChildren,
          setLoading: this.setLoadingFromWidget,
          publish: this.publish,
          publishMultipleValues:this.publishMultipleValues,
          sendNotification: this.sendNotification,
          closeDialog2:this.props.closeDialog,
        })}
      </Loader>
    )
  }
}

const mapStateToProps = function (state) {
  return {
    layoutName: state.project.layoutName,
    pubsub: state.pubsub
  }
}

export default connect(mapStateToProps)(Widget)
